import React from "react"
import styled from "@emotion/styled"
import Image from "../components/image"
import { Link } from "gatsby"

const CardGroup = styled.ul({
  display: "grid",
  listStyle: "none",
  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  // gap: 20,
  margin: 0,
  padding: 0
})

const CardItem = styled.li({
  // boxShadow: "0 0 5px rgba(0,0,0,0.1)",
  transition: "all 0.3s",
  margin: 0,
  padding: 0,
  height: "100%",
  background: "#fff",
  "&:hover": {
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    transform: "scale(1.04)",
    zIndex: 1,
  },
  "@media (max-width: 960px)": {
    padding: "0 10px",
  }
})

const CardContents = styled.div({
  padding: "10px 5px 35px",
  fontSize: "1.3rem",
  textAlign: "center",
  color: "rgba(0,0,0,0.8)",
})

const Card = ({ ...props }) => (
  <CardGroup>
    {props.item.map(i => (
      <CardItem key={i.node.id}>
        <Link to={i.node.fields.slug} style={{ textDecoration: "none" }}>
          {/* <Image filename="trianglify.png" alt="image" /> */}
          <Image
            filename={i.node.fields.slug.replace(/\//g, "") + "/thumbnail.png"}
            alt="image"
          />
          <CardContents>{i.node.frontmatter.title}</CardContents>
        </Link>
      </CardItem>
    ))}
  </CardGroup>
)

export const View = Card
